// import { MODULE_ATTENDANCE_STATUS } from './models/attendance';
import { MODULE_PANDEMIC_STATUS } from './models/pandemic';
import { MODULE_VACCINATION_STATUS } from './models/vaccination';
import { MODULE_AUTOSCREEN_STATUS } from './models/autoscreen';
import { MODULE_TEST_STATUS } from './models/test';

export * from './models/attendance';
export * from './models/pandemic';
export * from './models/test';
export * from './models/vaccination';
export * from './models/autoscreen';

export const MODULE_PERMISSION = {
    NONE: 0,
    VIEW: 1,
    EDIT: 2,
};

export const MODULE_PANDEMIC = 1;
export const MODULE_VACCINATION = 7;
export const MODULE_TEST = 8;
export const MODULE_SCREEN = 10;

export const MODULE_INCIDENT = 20;
export const MODULE_WC_INCIDENT = 12;
export const MODULE_AUTO_INCIDENT = 17;
export const MODULE_LIABILITY_INCIDENT = 18; 
export const MODULE_PROPERTY_INCIDENT = 19; 
export const MODULE_NON_REVENUE_INCIDENTS = 21; // MODULE_STUDENT_INCIDENT
export const MODULE_ACCESS_ASSISTANCE_INCIDENT = 22;
export const MODULE_HP_LIABILITY_INCIDENT = 23; 
export const MODULE_WORKPLACE_VIOLENCE_INCIDENT = 24;
export const MODULE_MESSAGE_CENTER_INCIDENT = 25;
export const MODULE_LIN_DIRECT_INCIDENT = 26;

export const MODULE_ALERTS = 2;
export const MODULE_BENEFITS = 3;
export const MODULE_INTEGRITY_LINE = 14;
export const MODULE_WECONNECT = 15;

/* Obsolete modules
export const MODULE_ATTENDANCE = 5; // hidden, not used
export const MODULE_REPORTS = 4;
export const MODULE_INJURY_REPORT = 6;
export const MODULE_INCIDENT_REPORTING = 9;
еxport const MODULE_TELEHEALTH = 11;
export const MODULE_IMMUNIZATION = 16;
export const MODULE_LIFEWORKS = 13;
*/

export const MODULES = [
    {
        key: 'pandemic', 
        title: 'Pandemic',
        id: MODULE_PANDEMIC,
        desc: 'Pandemic module',
        statuses: MODULE_PANDEMIC_STATUS,
        tile: true
    }, 
    {
        key: 'alerts',
        title: 'Alerts',
        id: MODULE_ALERTS,
        desc: 'Alerts module',
        tile: true
    }, 
    {
        key: 'benefits',
        title: 'Benefits',
        id: MODULE_BENEFITS,
        desc: 'Benefits module',
        tile: true
    }, 
    {
        key: 'test',
        title: 'Test',
        id: MODULE_TEST, 
        desc: 'Test module',
        statuses: MODULE_TEST_STATUS,
    }, 
    {
        key: 'vaccination',
        title: 'Vaccination',
        id: MODULE_VACCINATION, 
        desc: 'Vaccination module',
        statuses: MODULE_VACCINATION_STATUS,
    },
    {
        key: 'autoscreen',
        title: 'Autoscreen',
        id: MODULE_SCREEN,
        desc: 'Autocreening module',
        statuses: MODULE_AUTOSCREEN_STATUS,
    }, 
    {
        key: 'integrityLine',
        title: 'Integrity Line',
        id: MODULE_INTEGRITY_LINE,
        desc: 'Integrity Line module',
    },
    {
        key: 'incident',
        title: 'Incidents',
        id: MODULE_INCIDENT, 
        desc: 'Incident module',
    },
    {
        key: 'incidentWc',
        title: 'Workers\' Compensation Incidents',
        id: MODULE_WC_INCIDENT, 
        desc: 'WC Incident module',
    },
    {
        key: 'incidentAuto',
        title: 'Auto Incidents',
        id: MODULE_AUTO_INCIDENT, 
        desc: 'Auto Incident module',
    },
    {
        key: 'incidentLiability',
        title: 'Liability Incidents',
        id: MODULE_LIABILITY_INCIDENT, 
        desc: 'Liability Incident module',
    },
    {
        key: 'incidentProperty',
        title: 'Property Incidents',
        id: MODULE_PROPERTY_INCIDENT, 
        desc: 'Property Incident module',
    },
    /*
    @COMMENT Should be hidden for DS portal users
    {
        key: 'incidentNonRevenue',
        title: 'Non Revenue Incidents',
        id: MODULE_NON_REVENUE_INCIDENTS, 
        desc: 'Non Revenue Incident module',
    },
    {
        key: 'attendance',
        title: 'Attendance',
        id: MODULE_ATTENDANCE, 
        desc: 'Attendance module',
        statuses: MODULE_ATTENDANCE_STATUS,
        tile: true
    }, 
    {
        key: 'lifeworks',
        title: 'LifeWorks',
        id: MODULE_LIFEWORKS,
        desc: 'LifeWorks module',
    }, 
    {
        key: 'immunization',
        title: 'Immunizations',
        id: MODULE_IMMUNIZATION, 
        desc: 'immunization module',
    },
    */
];
